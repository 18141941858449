<template>
    <div style="width: 100%; height: 100%">
        <div style="width: 100%; height: 60px">
            <el-date-picker
                    style="margin: 15px 10px 10px 20px; height: 40px; width: auto; float: left"
                    v-model="selectDate"
                    @change="changeDate"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :shortcuts="shortcuts"
                    :default-time="defaultTime">
            </el-date-picker>


            <div :class="[isactive === 1?'tv-selected':'tv-normal']" @click="initCharts(1)" style="margin: 20px 10px 10px 20px; float: left">按小时</div>
            <div :class="[isactive === 2?'tv-selected':'tv-normal']" @click="initCharts(2)" style="margin: 20px 10px 10px 20px; float: left">按天</div>
            <div :class="[isactive === 3?'tv-selected':'tv-normal']" @click="initCharts(3)" style="margin: 20px 10px 10px 20px; float: left">按周</div>
        </div>
        <div v-loading="loading" style="width: 100%; height: calc(100% - 110px); margin-top: 15px">
            <v-chart ref="chart1" style="width: 100%; height: 100%" class="chart" :option="option" :auto-resize="true"/>
        </div>

    </div>

</template>
<script>

    import VChart from "vue-echarts";
    import { ref } from "vue";
    import {formatDate} from '../js/formatDate.js';
    // import { getSensorHistoryByID, getDeviceInfo } from "../../api/index";
    let that;
    export default {
        name: "AppSensorHistory",
        components: {
            VChart
        },
        setup: () => {
            const xLabel = ref([]);
            const mydatasAvg = ref([]);
            const mydatasMin = ref([]);
            const mydatasMax = ref([]);
            const mydatasRms = ref([]);
            const mydatasMse = ref([]);
            const mydatasSlope = ref([]);

            const device = ref({});
            const option = ref({
                title: {
                    text: "数据",
                    left: "20px"
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (params) {
                        let param = params[0];
                        let tt = formatDate(new Date(parseInt(param.value[0])),
                            that.type == 'hour' ? 'yyyy-MM-dd hh点'
                                : that.type == 'day' ? 'yyyy-MM-dd'
                                : that.type == 'week' ? 'yyyy-MM 第W周' :  'yyyy-MM-dd hh点');
                        let res = '时间 : ' + tt;
                        //使用for可以将需要的数据全部加到res
                        //注意下边的<br/>
                        for (let i = 0; i < params.length; i++) {
                            res += '<br/>' + params[i].seriesName + ' : ' + params[i].value[1];
                        }
                        return res;
                    }
                    // formatter: "{a} <br/>{b} : {c}"
                },
                xAxis: {
                    type: 'time',   // 还有其他的type，可以去官网喵两眼哦
                //    data: xLabel,   // x轴数据
                    minInterval:1000,
                    name: '',   // x轴名称
                    axisLabel:{
                        formatter: function (value, index) {
                            return formatDate(new Date(parseInt(value)),  that.type == 'hour' ? 'yyyy-MM-dd\nhh点'
                                : that.type == 'day' ? 'yyyy-MM-dd'
                                    : that.type == 'week' ? 'yyyy-MM\n第W周' :  'yyyy-MM-dd\nhh点');
                        }
                    },
                    // x轴名称样式
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18
                    },
                    axisLine: {             //坐标轴轴线相关设置。
                        lineStyle: {
                            color: '#7e7e7e',
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    //   name: '纵轴名称',   // y轴名称
                    // y轴名称样式
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18
                    },
                    axisLine: {             //坐标轴轴线相关设置。
                        lineStyle: {
                            color: '#7e7e7e',
                        }
                    },
                },
                legend: {

                    right: "center",
                    data: ["平均值", "最大值", "最小值", "均方根", "标准差", "斜率"]
                },
                dataZoom: [     // 主要是这一部分，他是折线图的缩放功能的开启
                    {
                        type: 'inside',
                        start: 0,
                        end: 100,
                        bottom: '5px'
                    }, {
                        start: 0,
                        end: 100,
                        bottom: '5px'
                    }
                ],
                toolbox: {
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        // restore: {},
                        saveAsImage: {}
                    }
                },
                series: [
                    {
                        name: "平均值",
                        type: "line",
                        data: mydatasAvg,
                      symbol: "circle", //拐点的形状
                    },
                    {
                        name: "最大值",
                        type: "line",
                        data: mydatasMax,
                      symbol: "circle", //拐点的形状
                    },
                    {
                        name: "最小值",
                        type: "line",
                        data: mydatasMin,
                      symbol: "circle", //拐点的形状
                    },
                    {
                        name: "均方根",
                        type: "line",
                        data: mydatasRms,
                      symbol: "circle", //拐点的形状
                    },
                    {
                        name: "标准差",
                        type: "line",
                        data: mydatasMse,
                      symbol: "circle", //拐点的形状
                    },
                    {
                        name: "斜率",
                        type: "line",
                        data: mydatasSlope,
                      symbol: "circle", //拐点的形状
                    }
                ]
            });

            return { option,xLabel,mydatasAvg, mydatasMax, mydatasMin, mydatasRms, mydatasMse, mydatasSlope, device };
        },
        data () {
            return {
                id:this.$route.params.id,
                token:this.$route.params.token,
                selectDate: [new Date(new Date().getTime()-24*60*60*1000), new Date()],
                valueList:[],
                isactive: 1,
                loading: true,
                defaultTime: [
                    new Date(2000, 1, 1, 0, 0, 0),
                    new Date(2000, 2, 1, 23, 59, 59)
                ],
                type:'hour',
                shortcuts:[
                    {
                        text:'最近24小时',
                        value:[new Date(new Date().getTime()-24*60*60*1000), new Date()]
                    },
                    {
                        text:'最近三天',
                        value:[new Date(new Date().getTime()-3*24*60*60*1000), new Date()]
                    },
                    {
                        text:'最近一周',
                        value:[new Date(new Date().getTime()-7*24*60*60*1000), new Date()]
                    },
                    {
                        text:'最近一月',
                        value:[new Date(new Date().getTime()-31*24*60*60*1000), new Date()]
                    }
                ]
            }
        },
        created(){
            that = this;

            this.getDeviceInfo()
            this.getDeviceHistoryByID()
        },
        methods: {
            initCharts(index){
                console.log(index)
                this.isactive = index;
                if (index == 1){
                    this.type = 'hour'
                }else if (index == 2){
                    this.type = 'day'
                }else if (index == 3){
                    this.type = 'week'
                }

                this.getDeviceHistoryByID()
            },
            changeDate(val){
                this.getDeviceHistoryByID()
            },
            getDeviceInfo(){
                this.apiUtils.appSyDeviceInfo({id:this.id}, this.token).then(res => {
                    if (res.code == 200){
                        this.device = res.data
                        if (this.device.unit!=null && this.device.unit.length>0) {
                            this.option.title.text = this.device.name + "("+ this.device.unit+")";
                        }else{
                            this.option.title.text = this.device.name;
                        }
                        console.error( this.device)
                    } else {
                        this.$message.error("获取设备信息失败! " + res.errInfo);
                        console.log(res);
                    }

                }).finally(() => {});

            },
            getDeviceHistoryByID(){
                this.loading = true;
                this.apiUtils.appSySensorHistoryByID({id:this.id, startTime:this.selectDate[0].getTime(), endTime:this.selectDate[1].getTime(), type:this.type}, this.token).then(res => {
                    if (res.code == 200){
                        this.valueList = res.data
                        let labels = [];
                        let avgs = [], mins = [], maxs = [], rmss = [], mses = [], slopes = [];
                        // let n = 0;
                        for (let valueListElement of this.valueList) {
                            labels.push(valueListElement.createTime)
                            // labels.push(n++)
                            avgs.push([valueListElement.createTime, valueListElement.average] )
                            mins.push([valueListElement.createTime, valueListElement.min] )
                            maxs.push([valueListElement.createTime, valueListElement.max] )
                            rmss.push([valueListElement.createTime, valueListElement.rms] )
                            mses.push([valueListElement.createTime, valueListElement.mse] )
                            slopes.push([valueListElement.createTime, valueListElement.slope] )
                        }
                        //  this.option.series[0].data = datas[0].data
                        //   this.option.xAxis.data = labels
                        this.xLabel = labels;
                        this.mydatasAvg = avgs;
                        this.mydatasMax = maxs;
                        this.mydatasMin = mins;
                        this.mydatasRms = rmss;
                        this.mydatasMse = mses;
                        this.mydatasSlope = slopes;
                    } else {
                        this.$message.error("获取设备列表失败! " + res.errInfo);
                        console.log(res);
                    }

                }).finally(() => {this.loading = false;});

            },
            resizeTheChart () {
                if (this.$refs && this.$refs.chart1) {
                    this.$refs.chart1.resize();
                }
            }
        },
        mounted () {
            window.addEventListener("resize", this.resizeTheChart)
        },
        beforeUnmount () {
            window.removeEventListener("resize", this.resizeTheChart);
        },

    }
</script>

<style scoped>
    .schart-box {
        display: inline-block;
        margin: 20px;
    }
    .schart {
        width: 600px;
        height: 400px;
    }
    .content-title {
        clear: both;
        font-weight: 400;
        line-height: 50px;
        margin: 10px 0;
        font-size: 22px;
        color: #1f2f3d;
    }
</style>